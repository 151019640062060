import React from "react";
import Images from "../../photos/Encipherhealthlogo31.png"

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const handleEncipherhealthClick = () => {
    window.location.href = "https://encipherhealth.com/";
  };

  return (
    <footer className="text-center" style={{ position: "fixed", bottom: 0, width: "100%", backgroundColor: "white", zIndex: 100 }}>
      <div className="d-flex">
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ margin: "0 auto" }}
        >
          <div className="d-flex align-items-center mb-3">
            <img
              src={Images}
              width={100}
              height={70}
              style={{margin:"-35px"}}
              // style={{ height: "71px", width: "64px",padding: "-55px"  }}
              alt="logo"
            />
            <p
              className="mb-0 hovered-text"
              style={{ fontSize: "14px", cursor: "pointer" }}
              onClick={handleEncipherhealthClick}
            >
              &copy; {currentYear} Encipher Health Private Limited
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
