// import * as React from "react";
import React, { useEffect, useState } from "react";
import ViewerWrapper from "./ViewerWrapper";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import axios from "axios";

const MultipleViewersSamePageExample = ({ fileID, pageNumber }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [selectFileURL, setSelectFileURL] = useState([]);
  const getPatientPdfFile = async (fileID, pageNumber) => {
    const response = await axios.get(
      `https://anesthesia.encipherhealth.com/api/v1/file/${fileID}#page=${pageNumber}`
    );
    if (response.data) {
      var result = response.data.value.pdfViewUrl;
      console.log(result,"fgwihietyioutioryu");
      setSelectFileURL(result)
    }
  };
 
  useEffect(() => {
    getPatientPdfFile(fileID, pageNumber);
    console.log(fileID,"popopo")
  }, []);

  const response = `https://anesthesiavalidator.blob.core.windows.net/input/MAA27511-e1cffbbe-e242-439a-a415-b126e293a3ab.pdf?sv=2022-11-02&se=2024-02-07T05%3A39%3A42Z&sr=b&sp=r&sig=iczI7HwKbZA30gX7upr3SlAUsJx%2FL%2Brq3WpcsyVXrYo%3D`;

  return (
    <div>
      <ViewerWrapper
        fileUrl={selectFileURL}
        plugins={[defaultLayoutPluginInstance]}
        // initialPage={pageNumber}
        pageNumber={pageNumber}
      />
      {/* <ViewerWrapper fileUrl={fileUrl} /> */}
    </div>
  );
};

export default MultipleViewersSamePageExample;
