// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headers {
    background: #FAFCFF;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
    width: 100%;
    height: 66px;
}

.header-title {
    color: #000;
    font-family: 'Cyberdyne';
    /* font-size: 20px; */
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.header-list {
    align-items: center;
}

.header-list > li {
    list-style: none;
    margin: 0 10px;
    padding: 21px;
    text-transform: uppercase;
    font-weight: 400;
    /* font-size: 16px; */
    cursor: pointer;
    font-weight: bold;
    font-size: 14px !important;
}

.active {
    border-bottom: 2px solid #180B8E;
    color:#180B8E;
}

.logout {
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/new-header/style.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,+CAA+C;IAC/C,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,wBAAwB;IACxB,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,yBAAyB;IACzB,gBAAgB;IAChB,qBAAqB;IACrB,eAAe;IACf,iBAAiB;IACjB,0BAA0B;AAC9B;;AAEA;IACI,gCAAgC;IAChC,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".headers {\n    background: #FAFCFF;\n    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);\n    width: 100%;\n    height: 66px;\n}\n\n.header-title {\n    color: #000;\n    font-family: 'Cyberdyne';\n    /* font-size: 20px; */\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n    letter-spacing: 2px;\n    text-transform: uppercase;\n}\n\n.header-list {\n    align-items: center;\n}\n\n.header-list > li {\n    list-style: none;\n    margin: 0 10px;\n    padding: 21px;\n    text-transform: uppercase;\n    font-weight: 400;\n    /* font-size: 16px; */\n    cursor: pointer;\n    font-weight: bold;\n    font-size: 14px !important;\n}\n\n.active {\n    border-bottom: 2px solid #180B8E;\n    color:#180B8E;\n}\n\n.logout {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
